import React from 'react'

import ImageRender from '../Common/Image'

const Image = (path) =>
  function (rowData) {
    return rowData[path] ? (
      !Array.isArray(rowData[path]) ? (
        <ImageRender data={rowData[path]} />
      ) : rowData[path].length ? (
        <ImageRender data={rowData[path][0]} />
      ) : (
        ''
      )
    ) : (
      ''
    )
  }
export default Image
